import { createReducer } from '@reduxjs/toolkit'
import { fetchDataChart, fetchData } from './actions'
import { elementDataChart } from './types'

interface globalState {
  dataChart: elementDataChart[]
  dataToken: elementDataChart[]
}

export const initialState: globalState = {
  dataChart: [],
  dataToken: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchDataChart, (state, action) => {
      state.dataChart = action.payload.dataChart
    })
    .addCase(fetchData, (state, action) => {
      state.dataToken = action.payload.dataToken
    }),
)
