import { useMemo, useState, createContext } from 'react'
import { useRouter } from 'next/router'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { Button, Flex, Menu as UikitMenu, useModal } from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'
// import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import ModalCreate from 'views/Info/Tokens/Modal/ModalCreate'
import ModalTrackingCreate from 'views/Info/Tokens/Modal/ModalTrackingCreate'
import axios from 'axios'
import { usePriceCakeBusd } from 'state/farms/hooks'
// import { usePhishingBannerManager } from 'state/user/hooks'
import UserMenu from './UserMenu'
import { useMenuItems } from './hooks/useMenuItems'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'
import { SettingsMode } from './GlobalSettings/types'

const RefreshCreate = []

export const RefreshCreateGlobal = createContext(RefreshCreate)

const Menu = (props) => {
  const { isDark, setTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  // const [showPhishingWarningBanner] = usePhishingBannerManager()

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  const [isLogOut, setLogOut] = useState(false)
  const router = useRouter()

  function handleClickLogIn() {
    router.push('/login')
    setLogOut(false)
  }
  async function handleClickLogOut() {
    const tokenAuth = localStorage.getItem('token')
    setLogOut(true)
    router.push('/info/token/0xc643e83587818202e0fff5ed96d10abbc8bb48e7')

    await axios.get('https://dog-watcher-api.deltalabsjsc.com:4001/api/v1/products', {
      headers: {
        'Content-Type': 'application/json',
        token: `${tokenAuth}`,
      },
    })
    localStorage.removeItem('token')
  }

  const [handleClickTeamCreate] = useModal(<ModalCreate onRefresh={(newValue) => RefreshCreate.push(newValue)} />)
  const [handleClickTrackingCreate] = useModal(
    <ModalTrackingCreate onRefresh={(newValue) => RefreshCreate.push(newValue)} />,
  )

  const tokenAuth = localStorage.getItem('token')

  return (
    <UikitMenu
      linkComponent={(linkProps) => {
        return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
      }}
      rightSide={
        <>
          <Flex
            mr={50}
            width="100%"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{ gap: '20px' }}
          >
            {tokenAuth !== null ? (
              <>
                {/* <CustomButton disabled={isLogOut === true}>{t('Create Team')}</CustomButton>
                <CustomButton disabled={isLogOut === true}>{t('Create Tracking')}</CustomButton> */}
                <CustomButton onClick={() => handleClickTeamCreate()}>{t('Create Team')}</CustomButton>
                <CustomButton onClick={() => handleClickTrackingCreate()}>{t('Create Tracking')}</CustomButton>
                <CustomButton disabled={isLogOut === true} onClick={() => handleClickLogOut()}>
                  {t('Sign out')}
                </CustomButton>
              </>
            ) : (
              <>{/* <CustomButton onClick={() => handleClickLogIn()}>{t('Sign in')}</CustomButton> */}</>
            )}
          </Flex>
          <NetworkSwitcher />
          {/* <UserMenu /> */}
        </>
      }
      // banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={menuItems}
      subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
      footerLinks={getFooterLinks}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      buyCakeLabel={t('Buy CAKE')}
      {...props}
    />
  )
}

export default Menu

const CustomButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  width: 161px;
  height: 50px;
  border: 3px solid #e6e8ec;
  border-radius: 90px;
  background: transparent;
  color: #23262f;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  @media screen and (max-width: 1020px) {
    display: none;
  }
`
const FlexNotMobile = styled(Flex)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`
